import { createGlobalStyle } from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';

export const GlobalStyles = createGlobalStyle`
	:root {
			--color-white: ${ThemeColors.white};
			--color-gray1: ${ThemeColors.gray1};
			--color-gray2: ${ThemeColors.gray2};
			--color-gray3: ${ThemeColors.gray3};
			--color-green1: ${ThemeColors.green1};
			--color-green2: ${ThemeColors.green2};
			--color-green3: ${ThemeColors.green3};
			--color-brown1: ${ThemeColors.brown1};
			--color-brown2: ${ThemeColors.brown2};
			--color-brown3: ${ThemeColors.brown3};
			--color-black: ${ThemeColors.black};
			--color-blue1: ${ThemeColors.blue1};
			--color-blue2: ${ThemeColors.blue2};
			--color-blue3: ${ThemeColors.blue3};
	}
	
	body,
	html {
			margin: 0;
			color: var(--color-black);
			background: #fff;
	}
	
	* {
			box-sizing: border-box;
			font-family: 'Maven Pro', sans-serif;
	}
	
	h2 {
			font-size: 24px;
			font-weight: 500;
	}
	
	button {
			border: none;
			outline: none;
			box-shadow: none;
			background: none;
			cursor: pointer;
			padding: 0;
	}
	
	
	h1, h2, h3, h4, h5, h6 {
			font-weight: 500;
	}
	
	a {
			text-decoration: none;
	}
	
	@media(max-width: 767px) {
		* {
			-webkit-touch-callout: none;
			-webkit-overflow-scrolling: auto;
		}
		
		*:not(input, textarea) {
			user-select: none;
		}
		
		*::-webkit-scrollbar {
  		width: 0px;
		}
	}
	
.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #7275C0;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #7275C0;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.leaflet-container {
background :#fff;
}
`;
