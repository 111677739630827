import styled from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';
import { ComponentPropsWithoutRef } from 'react';

const Nav = styled.nav``;

const NavItem = styled.a`
	font-size: 40px;
	color: ${ThemeColors.white};
	transition: color 0.2s;
	font-weight: 500;
	cursor: pointer;

	&:hover {
		color: ${ThemeColors.green2};
	}
`;

type Props = ComponentPropsWithoutRef<'nav'> & {
	onAboutItemClick(): void;
};

export const MenuNav = ({ onAboutItemClick, ...props }: Props) => {
	return (
		<Nav {...props}>
			<NavItem onClick={onAboutItemClick}>O Wyjehau</NavItem>
		</Nav>
	);
};
