import type { AppProps } from 'next/app';
import Head from 'next/head';

import { Analytics } from '@vercel/analytics/react';

import { GlobalStyles } from '../../styles/global-styles';
import { StatefulMainMenu } from '../components/main-menu/main-menu';

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<>
			<Analytics />
			<Head>
				<meta
					name="viewport"
					content="width=device-width,viewport-fit=cover, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
				/>
			</Head>
			<GlobalStyles />
			<StatefulMainMenu />
			<Component {...pageProps} />
		</>
	);
}

export default MyApp;
