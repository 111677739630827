import { createDomain } from 'effector';
import { useStore } from 'effector-react';

export class MainMenuStore {
	private domain = createDomain('Main Menu');

	open = this.domain.createEvent();
	close = this.domain.createEvent();

	isOpen$ = this.domain
		.createStore(false)
		.on(this.open, () => true)
		.on(this.close, () => false);

	use() {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isOpen = useStore(this.isOpen$);

		return {
			isOpen,
		};
	}
}

export const mainMenuStoreSingleton = new MainMenuStore();
