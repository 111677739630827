import styled, { keyframes } from 'styled-components';
import { transparentize } from 'polished';
import { ThemeColors } from '@wyjehau/theme';
import { useCallback } from 'react';

import { mainMenuStoreSingleton } from '../../main-menu.store';

import { ConnectedCloseMenuButton } from './menu-button/menu-button';
import { MenuNav } from './menu-nav/menu-nav';
import { MenuContactUs } from './menu-contact-us/menu-contact-us';

const rotate = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
	position: fixed;
	z-index: 9999;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: ${transparentize(0.1, ThemeColors.blue3)};
	animation: ${rotate} 0.1s;
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const PositionedCloseButton = styled(ConnectedCloseMenuButton)`
	top: 32px;
	right: 32px;
	position: absolute;
`;

const PositionedNav = styled(MenuNav)`
	margin-bottom: 40px;
`;

type Props = {
	onClose(): void;
};

export const MainMenu = ({ onClose }: Props) => {
	const handleAboutUsItem = useCallback(() => {
		import('../../welcome-modal-visibility')
			.then((m) => m.welcomeModalVisibilityInstance)
			.then((instance) => {
				instance.show();
				onClose();
			});
	}, [onClose]);

	return (
		<Wrapper>
			<PositionedCloseButton />
			<InnerWrapper>
				<PositionedNav onAboutItemClick={handleAboutUsItem} />
				<MenuContactUs />
			</InnerWrapper>
		</Wrapper>
	);
};

export const StatefulMainMenu = () => {
	const { isOpen } = mainMenuStoreSingleton.use();

	if (!isOpen) return null;

	return <MainMenu onClose={mainMenuStoreSingleton.close} />;
};
