import styled, { css } from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';
import { ComponentPropsWithoutRef, useCallback } from 'react';

import { ReactComponent as MenuIcon } from '../../../icons/assets/menu.svg';
import { ReactComponent as CloseIcon } from '../../../icons/assets/close.svg';
import { mainMenuStoreSingleton } from '../../../main-menu.store';

const commonStyles = css`
	width: 48px;
	height: 48px;
	border-radius: 10px;
	cursor: pointer;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
`;

const NormalButton = styled.button`
	${commonStyles}
	background: ${ThemeColors.white};
`;

const CloseButton = styled.button`
	${commonStyles};
	background: ${ThemeColors.blue2};
	color: ${ThemeColors.white};
`;

const MenuButton = (props: ComponentPropsWithoutRef<'button'>) => {
	return (
		<NormalButton {...props}>
			<MenuIcon />
		</NormalButton>
	);
};

const CloseMenuButton = (props: ComponentPropsWithoutRef<'button'>) => {
	return (
		<CloseButton {...props}>
			<CloseIcon />
		</CloseButton>
	);
};

export const ConnectedMenuButton = ({
	onClick,
	...props
}: ComponentPropsWithoutRef<'button'>) => {
	const handleClick = useCallback(() => {
		mainMenuStoreSingleton.open();
	}, []);

	return <MenuButton {...props} onClick={handleClick} />;
};

export const ConnectedCloseMenuButton = ({
	onClick,
	...props
}: ComponentPropsWithoutRef<'button'>) => {
	const handleClick = useCallback(() => {
		mainMenuStoreSingleton.close();
	}, []);

	return <CloseMenuButton {...props} onClick={handleClick} />;
};
