import styled from 'styled-components';
import { ThemeColors } from '@wyjehau/theme';

const Wrapper = styled.div`
	text-align: center;
	color: ${ThemeColors.white};

	& strong,
	a {
		display: block;
		color: ${ThemeColors.white};
		font-size: 20px;
	}
`;

export const MenuContactUs = () => {
	return (
		<Wrapper>
			<strong>Napisz do nas</strong>
			<a href="mailto:lukasz@monolog.tech">lukasz@monolog.tech</a>
		</Wrapper>
	);
};
