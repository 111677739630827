// src/index.ts
var ThemeColors = {
  white: "#FFFFFF",
  gray1: "#F3F3F3",
  gray2: "#DBD9DE",
  gray3: "#8C8C8C",
  green1: "#EAF5F4",
  green2: "#65B3AA",
  green3: "#3C8079",
  brown1: "#FCF1ED",
  brown2: "#E18D70",
  brown3: "#AB5336",
  black: "#2C2D2F",
  blue1: "#DFDFF7",
  blue2: "#7275C0",
  blue3: "#3D3988"
};
export {
  ThemeColors
};
